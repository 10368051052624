.carousel {
  $root: &;
  $buttonSizeM: 18px;
  $buttonSizeT: 30px;
  $buttonSizeD: 40px;
  position: relative;

  &__wrapper {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    .carousel--with-border & {
      box-shadow: 0 0 0 1px $carouselButtonPrimaryColor;
    }

    .product-box-slider__slider & {
      margin: 0 auto -20px;
      padding: 0 0 20px;
    }
  }

  &__container {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 100%;
    display: flex;
    transition-property: transform;
    align-items: stretch;
  }

  &__slide {
    position: relative;
    flex-shrink: 0;
    z-index: 2;

    &--active {
      z-index: 3;
    }
  }

  &__button {
    color: $carouselButtonPrimaryColor;
    z-index: 1;
    width: $buttonSizeM * 4;
    height: $buttonSizeM;
    text-align: center;

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      top: 0;
      height: 100%;
      width: $buttonSizeT;
      color: $carouselButtonTextColor;
    }

    @media (min-width: $screen-desktop-xl) {
      width: $buttonSizeD;
    }

    &:hover {
      @media (min-width: $screen-tablet-portrait) {
        .carousel__button-icon {
          color: $carouselButtonHoverTextColor;
          background: $carouselButtonHoverBackgroundColor;
        }
      }
    }

    &:focus {
      outline: 0 solid transparent;
    }

    &--prev {
      @media (min-width: $screen-tablet-portrait) {
        left: -20px;
      }

      @media (min-width: $screen-desktop-xl) {
        left: -65px;
      }

      .carousel__button-icon {
        @include iconFont($carouselButtonLeftIcon) {
          color: inherit;
          font-size: $carouselButtonFontSizeMobile;
          text-shadow: $carouselButtonTextShadow;
          left: 48%;

          @media (min-width: $screen-tablet-portrait) {
            color: $carouselButtonTextColorDesktop;
            font-size: $carouselButtonFontSizeTablet;
          }

          @media (min-width: $screen-desktop-xl) {
            font-size: $carouselButtonFontSizeDesktop;
          }
        }
      }
    }

    &--next {
      @media (min-width: $screen-tablet-portrait) {
        right: -20px;
      }

      @media (min-width: $screen-desktop-xl) {
        right: -65px;
      }

      .carousel--fade-white & {
        background: linear-gradient(to left, $whiteColor 0%, transparent 100%); // stylelint-disable-line  plugin/no-unsupported-browser-features
      }

      .carousel__button-icon {
        @include iconFont($carouselButtonRightIcon) {
          color: inherit;
          font-size: $carouselButtonFontSizeMobile;
          text-shadow: $carouselButtonTextShadow;

          @media (min-width: $screen-tablet-portrait) {
            color: $carouselButtonTextColorDesktop;
            font-size: $carouselButtonFontSizeTablet;
          }

          @media (min-width: $screen-desktop-xl) {
            font-size: $carouselButtonFontSizeDesktop;
          }
        }
      }

      .carousel--with-border & {
        right: -20px;

        @media (min-width: $screen-desktop-xl) {
          right: -33px;
        }
      }
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  &__button-icon {
    text-decoration: none;
    color: $carouselButtonPrimaryColor;
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: $buttonSizeM;
    transition: background .15s linear;
    left: 0;
    transform: translateY(1px);

    &::before {
      line-height: 1;

      @media (min-width: $screen-tablet-portrait) {
        color: $carouselButtonTextColor;
        position: absolute;
        left: 52%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      @include carouselButtonRound($buttonSizeT);
    }

    @media (min-width: $screen-desktop-xl) {
      width: $buttonSizeD;
      height: $buttonSizeD;
    }
  }

  &__pagination-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 16px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
      margin: 0;
    }
  }

  &__pagination {
    width: 100% !important;
    padding: 0;
    display: none;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &--show {
      display: block;
      visibility: hidden;
    }

    &--visible {
      visibility: visible;
    }
  }

  &__page-info {
    color: $brandSecondaryColor;
    font-size: $carouselPaginationFontSize;
    line-height: 110%;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__pagination-bullet {
    display: none;
    background: $carouselPaginationBackgroundColor;
    margin: 0 2px;
    height: 3px;
    width: 20px;

    &--current-main,
    &--current-prev,
    &--current-prev-prev,
    &--current-next,
    &--current-next-next {
      display: block;
    }

    &--current {
      background: $carouselPaginationHighlightColor;
    }
  }

  &__product-box {
    .product-box__inner {
      border: 0 none;
    }
  }

  &__no-slider {
    .carousel__container {
      justify-content: flex-start;
    }

    .carousel__slide {
      flex-shrink: inherit;
    }
  }

  &--progress-bar {
    .carousel__page-info {
      display: block;
      text-align: center;

      @media (min-width: $screen-desktop) {
        display: none;
      }
    }

    #{$root}__button {
      color: $carouselButtonPrimaryColor;
      z-index: 1;
      text-align: center;
      position: relative;
      top: 0;
      height: 100%;
      width: 30px;
      display: block;

      @media (min-width: $screen-tablet-portrait) {
        top: -3px;
      }

      @media (min-width: $screen-desktop) {
        position: absolute;
        top: 0;
        height: 100%;
        width: $buttonSizeT;
        color: $carouselButtonTextColor;
      }

      @media (min-width: $screen-desktop-xl) {
        width: $buttonSizeD;
      }

      &:focus {
        outline: 0 solid transparent;
      }

      &:hover {
        #{$root}__button-icon {
          background: inherit;
          color: inherit;

          @media (min-width: $screen-desktop) {
            color: $carouselButtonHoverTextColor;
            background: $carouselButtonHoverBackgroundColor;
          }
        }
      }

      &--prev {
        @media (min-width: $screen-desktop) {
          left: -20px;
        }

        @media (min-width: $screen-desktop-xl) {
          left: -40px;
        }

        .carousel__button-icon {
          @include iconFont(arrowLeft) {
            display: flex;
            color: inherit;
            background: inherit;
            font-size: $carouselButtonFontSizeMobile;
            text-shadow: $carouselButtonTextShadow;
            left: 48%;

            @media (min-width: $screen-desktop) {
              font-size: $carouselButtonFontSizeTablet;
            }

            @media (min-width: $screen-desktop-xl) {
              font-size: $carouselButtonFontSizeDesktop;
            }
          }
        }
      }

      &--next {
        @media (min-width: $screen-desktop) {
          right: -20px;
        }

        @media (min-width: $screen-desktop-xl) {
          right: -40px;
        }

        .carousel__button-icon {
          @include iconFont(arrowRight) {
            display: flex;
            color: inherit;
            background: inherit;
            font-size: $carouselButtonFontSizeMobile;
            text-shadow: $carouselButtonTextShadow;

            @media (min-width: $screen-desktop) {
              font-size: $carouselButtonFontSizeTablet;
            }

            @media (min-width: $screen-desktop-xl) {
              font-size: $carouselButtonFontSizeDesktop;
            }
          }
        }
      }

      &.swiper-button-disabled {
        opacity: 0;
      }
    }

    #{$root}__button-icon {
      text-decoration: none;
      color: $carouselButtonPrimaryColor;
      display: inline-block;
      cursor: pointer;
      width: 100%;
      height: $buttonSizeM;
      transition: background .15s linear;
      left: 0;
      transform: translateY(1px);
      background: inherit;

      &::before {
        line-height: 1;

        @media (min-width: $screen-desktop) {
          color: $carouselButtonTextColor;
          position: absolute;
          left: 52%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      @media (min-width: $screen-desktop) {
        @include carouselButtonRound($buttonSizeT);
        color: $carouselButtonTextColor;
      }

      @media (min-width: $screen-desktop-xl) {
        width: $buttonSizeD;
        height: $buttonSizeD;
      }
    }

    .carousel__pagination-wrapper {
      display: flex;
      margin: 0 auto;
      max-width: 1276px;

      @media (max-width: 1199px) {
        position: relative;
      }

      @media (min-width: $screen-desktop) {
        display: block;
      }
    }

    .carousel__pagination {
      margin: 0;
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: -33px;
      display: flex;
      left: 0;
    }

    .carousel__pagination-button {
      display: inline-block;
      padding: 10px 5px 20px;
      width: 50px;
      font-size: 0;
      position: relative;
      transition: color .2s;
      cursor: pointer;

      @media (min-width: $screen-tablet-portrait) {
        padding: 10px 10px 20px;
        width: 120px;
      }

      &:hover .carousel__pagination-progress-bar,
      &--active .carousel__pagination-progress-bar {
        background-color: $carouselPaginationHighlightColor !important;
      }

      &--hide {
        display: none;
      }
    }

    .carousel__pagination-progress-bar {
      display: block;
      width: 100%;
      height: 3px;
      background-color: $carouselPaginationBackgroundColor;
    }
  }

  &__skip-to-bottom-link {
    position: absolute;
    top: -30px;
    left: 5px;
    opacity: 0;
    transition: opacity .2s;
    z-index: -1;

    .hero-slider & {
      top: 5px;
    }

    &:focus-visible { // stylelint-disable-line
      outline-offset: 4px;
      z-index: 500;
      opacity: 1;
    }
  }

  &__skip-to-bottom-anchor {
    position: absolute;
    bottom: 50px;

    @media (min-width: $screen-tablet-portrait) {
      bottom: 60px;
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
carousel,
[is="carousel"] {
  display: flex;
  width: calc(100% - 40px);
  margin: 0 auto;

  .hero-slider & {
    width: 100%;
  }
}
